.btn {
    border-radius: 50px;
    
    &:focus {
        &::before{
            border-radius: inherit;
        }
    }
}

.btn-transparent {
    background-color: unset !important;
    border: none !important;
    color: #4c4c4c !important;

    &:hover, &:active {
        background-color: unset;
        border: none !important;
        color: #4c4c4c;
    }

    &:focus {
        &::before{
            border: none;
        }
    }

    @media (max-width: 991px) {
        padding: .3rem .5rem !important;
    }
}

.language {
    padding: 0.5rem 0 !important;
    margin: unset;
}

.dropdown-menu {
    min-width: unset;

    .dropdown-item {
        &:active {
            background-color: #C32D3A;
        }
    }
}
