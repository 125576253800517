.home-body-content-wrapper {
    font-family: Helvetica, Arial, sans-serif;
    background-color: #F3F3F3;
    padding-top: 4rem;
    padding-bottom: 3rem;
    .body-content {
        margin: 0 auto 3rem auto;
        @media screen and (max-width: 600px) {
            margin: 0 auto 2rem auto;
        }
        .guide {
            font-size: 1.5em;
            font-weight: 600;

            @media (max-width: 991px) {
                text-align: center;
            }
        }
    }

    .home-videos {
        overflow-x: auto;
        flex-wrap: nowrap;
        // flex-wrap: wrap;
        // @media screen and (max-width: 700px) {
        //     overflow-x: auto;
        //     flex-wrap: nowrap;
        // }
    }

    // video{
    //     aspect-ratio: 16/9;
    //     width: 100%;
    //     max-width: 576px;;
    // }
    
    .section-mw {
        max-width: 1400px!important;
    }

    .courses {
        margin-top: 20px;
        max-width: 1350px;
        flex-wrap: wrap;
        @media (max-width: 575px){
         flex-wrap: nowrap;
         overflow-x: auto;
         max-width: unset;
         >.pgn__card {
            width: 300px;
            flex-shrink: 0!important;
         }
        }
        >.pgn__card {
            &:hover {
                text-decoration: none!important;
            }
            max-width: 300px;
            margin: 0 19px 19px 0;
            flex-basis: calc(100% - 20px);
            flex-grow: 0;
            flex-shrink: 1;
            @media (min-width: 576px){
                flex-basis: calc(50% - 20px);
                flex-grow: 0;
                flex-shrink: 1;
            }
            @media (min-width: 768px){
                flex-basis: calc(33.333% - 20px);
                flex-grow: 0;
                flex-shrink: 1;
            }
            @media (min-width: 1200px) {
                flex-basis: calc(25% - 20px);
                flex-grow: 0;
                flex-shrink: 1;
            }
        }
        [class*="pgn__card-header-title-"] {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .pgn__card-wrapper-image-cap {
            height: 140px;
        }
        .pgn__card-header {
            margin-top: 20px;
        }
        .space {
            height: 40px;
        }
        .pgn__card-header-content {
            margin-top: 0;
        }
        .pgn__card .pgn__card-footer.vertical {
            display: block;
            position: absolute;
            bottom: 0;
        }
        .badge-light {
            color: var(--norm-text);
            padding: .25rem .5rem;
            font-size: 0.875rem;
            font-weight: 400;
        }

        .desc {
            font-size: 0.875rem;
            color: #616161;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3; 
            -webkit-box-orient: vertical;
        }

        .foot {
            background-color: #0161CC;
            font-size: 0.875rem;
            color: white;
            width: 100%;
            bottom: 0;
            position: absolute;
            margin-top: 1rem;
            padding: 8px 1rem;
            display: flex;
            overflow: hidden;
            border-radius: 0 0 0.425rem 0.425rem!important;
            >div {
                display: flex;
                align-items: center;
                .pgn__icon {
                    display: flex;
                    margin-right: 1px;
                    align-items: center;
                    svg {
                        margin-top: -1px;
                        height: 1.2rem;
                    }
                }
                &:nth-child(2) {
                    margin-left: auto;
                }
            }

        }
        .pgn__card-logo-cap {
            height: auto;
        }
        .pgn__card-header .pgn__card-header-title-md {
            font-size: 1.2rem;
        }
        .pgn__card-section {
            padding: 0.5rem 1.25rem 1.25rem 1.25rem;
        }
    }

    .tl {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 1.5em;
        margin: 0;
        font-weight: 600;
        @media screen and (max-width: 600px) {
            font-size: 1.5em;
        }
    }

    .view-more-courses {
        margin-right: 19px;
        display: block;
        margin-left: auto;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        border: 1px solid #006DAA;
        .pgn__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5rem;
            width: 2.5rem;
            svg {
                height: 2rem;
                width: auto;
            }
        }
    }

    .pgn__card .pgn__card-wrapper-image-cap .pgn__card-logo-cap {
        height: 60px!important;
        width: auto!important;
    }
}

