@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800,900');

.banner {
    max-width: 1450px!important;
    @media (max-width: 600px) {
        max-height: 330px;
    }
    height: calc(80vh - 100px);
    position: relative;
    img {
        position: absolute;
        max-width: 100%;
        bottom: 0;
        right: 0;
        max-height: 100%;
    }

    .text-over {
        top: 15%;
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 2.1rem;

        @media (max-width: 767px) {
            max-height: 300px;
            top: 30%;
        }

        .text-wrapper {
            .text-name {
                font-family: "Montserrat",sans-serif;
                font-size: 3rem;
                font-weight: 800;
                color: #E30613;
                line-height: 1.2;
                max-width: 400px;
                border-radius: 0 30px 30px 0;

                @media (max-width: 767px) {
                    font-size: 1.5rem;
                    width: calc(100vw/4 + 2em);
                }

                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 4rem;
                    width: calc(100vw/5 + 2em);
                }

                @media (min-width: 992px) {
                    font-size: 5rem;
                }
            }

            .text-title {
                font-family: "Montserrat",sans-serif;
                font-size: 3rem;
                font-weight: 800;
                color: var(--primary);
                line-height: 1.2;
                
                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 3rem;
                }

                @media (min-width: 992px) {
                    font-size: 5rem;
                }
                @media (max-width: 767px) {
                    font-size: 1.5rem;
                }
            }

        }
    }
}

.search-area {
    form[role="search"] {
        // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3) !important;
        background-color: white;
        width: fit-content;
        max-width: 430px;
        border: 1px solid #C1C1C1;
        padding: 5px 5px;
        border-radius: 30px;
        &:focus-within {
            border: 1px solid var(--primary);
        }

        @media (max-width: 600px) {
            padding: 0 3px;
            button[type="submit"] {
                padding: 0.4375rem 0.75rem;
                font-size: 0.875rem;
                line-height: 1.4286;
            }

            button[type="reset"] {
                padding: 0;
            }

            input {
                font-size: 1rem;
                line-height: 1rem;
                padding: 0 5px;
            }
        }
    }

    padding: 1rem 1rem 0 0;
    .title {
        color: white;
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 0.75rem;
    }
    .pgn__searchfield_wrapper {
        max-width: 430px;
        border: 0!important;
        box-shadow: none;
        input {
            border: 0!important;
            outline: none!important;
            border-radius: 30px;
        }
        button[type="reset"] {
            color: var(--norm-text);
            border: 0;
            border-radius: 0;
            background-color: white;
        }
        &::after {
            border: 0!important;
            outline: none!important;
        }
    }
    .pgn__searchfield.pgn__searchfield--external .btn-primary {
        background: #d23228!important;
        white-space: nowrap;
        border: 0!important;
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        &::before, &::after  {
            border: 0!important;
        }
        &:focus {
            border: 0!important;
        }
        &:hover {
            background-color: #C32D3A;
        }
    }

    .view-all-search-result {
        background-color: #f2f0ef!important;
        a {
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: var(--primary)!important;
                background-color: #f2f0ef!important;
                text-decoration: underline!important;
            }
        }
    }

    .search-dropdown {
        height: 500px;
        width: 100%;
        max-width: 430px;
        height: auto;
        max-height: 80vh;
        overflow-y: auto;
        width: calc(100% - 30px);
        max-width: 430px;
        background-color: white;
        position: absolute;
        z-index: 1;
        margin-top: -1px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                a {
                    color: var(--norm-text);
                    cursor: pointer;
                    padding: 0.5rem 1rem;
                    display: block;
                    &:hover {
                        text-decoration: none;
                        background-color: var(--primary);
                        color: white;
                    }
                    .badge {
                        font-weight: 400;
                        font-size: 0.875rem;
                        padding: .25rem .5rem;
                    }
                    .badge-light {
                        color: var(--norm-text);
                    }
                }
            }
        }
    }
}

.search-result-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    .page-nav {
        font-size: 0.875rem;
        a {
            color: #1a387a;
        }
    }
    .pgn__icon {
        height: 1.2rem;
    }
    .pgn__breadcrumb {
        margin-top: -1px;
    }
    .search-paging {
        margin-top: 20px;
        .pagination {
            justify-content: center;
        }
    }

    .title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1re;
    }

    .card-list {
        margin-top: 20px;
        max-width: 1500px;
        flex-wrap: wrap;
        >.pgn__card {
            width: 350px;
            margin: 0 20px 20px 0;
        }
        [class*="pgn__card-header-title-"] {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .space {
            height: 80px;
        }
        .org-and-number {
            font-size: 0.875rem;
            padding: 1rem 1.25rem 0 1.25rem;
            font-weight: 400;
            display: flex;
            >div {
                &:not(:last-child){
                    margin-right: 1rem;
                }
            }
        }
        .pgn__card-header-content {
            margin-top: 0;
        }
        .pgn__card .pgn__card-footer.vertical {
            display: block;
            position: absolute;
            bottom: 0;
        }
        .badge-light {
            color: var(--norm-text);
            padding: .25rem .5rem;
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
}