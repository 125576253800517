.object-fit-cover {
    object-fit: cover;
}

.clickable {
    cursor: pointer;
}

.cvideo {
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.15);
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 300px;
    flex-shrink: 0;
    flex-basis: calc(33% - 20px);
    video {
        aspect-ratio: 16/9;
        max-width: 500px;
    }
}