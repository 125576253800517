@import "@edx/brand/paragon/fonts.scss";
@import "@edx/brand/paragon/variables.scss";
@import "@openedx/paragon/scss/core/core.scss";
@import "@edx/brand/paragon/overrides.scss";

@import './homepage/index.scss';

@import "~@edx/frontend-component-header/dist/index";
@import "~@edx/frontend-component-footer/dist/footer";

:root {
    --norm-text: rgba(0,0,0,0.87);
}

body {
    color: rgba(0,0,0,0.87);
    font-family:'Open Sans', 'Helvetica Neue', 'Roboto','Arial', sans-serif;
}